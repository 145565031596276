<template>
  <div class="product-main">
    <div class="product-main-l">
      <img :src="data.img">
    </div>
    <div class="product-main-r">
      <div class="title">
        {{ data.title }}
      </div>
      <div class="content">
        {{ content230 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '',
        img: '',
        title: '',
        content: ''
      })
    }
  },
  components: {
  },
  computed: {
    content230 () {
      if (this.data.content.length > 230) {
        return this.data.content.substring(0, 230) + '...'
      } else {
        return this.data.content
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.product-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 300px;
  margin-bottom: 66px;
  .product-main-l {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-main-r {
    width: 49%;
    .title {
      font-size: 20px;
      color: #000000;
      font-weight: bold;
      line-height: 27px;
      padding-left: 10px;
      border-left: 4px solid #3060e4;
      margin-bottom: 40px;
    }
    .content {
      height: 130px;
      font-size: 14px;
      color: #6d7281;
    }
  }
}
</style>
