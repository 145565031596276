<template>
  <div class="service-system">
    <v-banner-box :data="swiperData" />
    <v-nav-bar />
    <v-product-overview :data="overviewData" />
    <div
      class="banner100vw banner100vw-20p30t banner100vw-linear-gradient"
      id="scenarios"
    >
      <v-app-scenarios :scenarios-data="scenariosData" />
    </div>
    <v-industry :pain-points="painPoints" />
    <div
      class="banner100vw banner100vw-20p30t banner100vw-linear-gradient"
      id="frame"
    >
      <v-frame :frameimg="frameImg" />
    </div>
    <v-technology :technology="technology" />
    <div
      class="
        banner100vw banner100vw-20p30t banner100vw-linear-gradient
        productadvantage-bg
      "
      id="advantage"
    >
      <v-productadvantage
        :typeslist1="typesList1"
        :typeslist2="typesList2"
      />
    </div>
    <v-app-stories :case-list="caseList" />
  </div>
</template>

<script>
// NOTE :  产品中心
import vBannerBox from '@/components/banner/bannerBox.vue'
import vNavBar from './components/navBar/index.vue'
import vProductOverview from './components/productOverview/index.vue'
import vAppScenarios from './components/appScenarios/index.vue'
import vIndustry from './components/industry/index.vue'
import vFrame from './components/frame/index.vue'
import vTechnology from './components/technology/index.vue'
import vProductadvantage from './components/productadvantage/index.vue'
import vAppStories from './components/appStories/index.vue'
export default {
  components: {
    vBannerBox,
    vNavBar,
    vProductOverview,
    vAppScenarios,
    vIndustry,
    vFrame,
    vTechnology,
    vProductadvantage,
    vAppStories
  },
  computed: {
    typesList1 () {
      return this.characteristic.slice(0, 3)
    },
    typesList2 () {
      return this.characteristic.slice(3, 6)
    }
  },
  data () {
    return {
      swiperData: [],
      overviewData: {
        title: '电子档案系统（PC&移动端）',
        content: [
          '电子档案必须具有独立性，因此电子档案系统必须是独立于电子服务系统和电子交易系统独立存在的，同样电子档案数据也必须是不依赖任何系统独立存在的。',
          '文锐电子档案系统是完全独立的系统，数据归档后自动生成每个项目的档案文件（PDF和XML文件），电子服务系统和电子交易系统的运行状况是否正常、是否更换系统、甚至系统是否存在，均不会影响电子档案的管理和利用。'
        ],
        img: '/static/img/productCenter/archivesSystem.png'
      },
      scenariosData: {
        id: '1',
        img: '/static/img/productCenter/c-1.png',
        title: '实现招投标过程中资料储存',
        content:
          '对招投标过程所有资料上链存储，并保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。'
      },
      painPoints: [
        {
          src: '/static/img/productCenter/a-1.png',
          content: '档案存储不够全面，存储时间不能满足相关要求'
        },
        {
          src: '/static/img/productCenter/a-2.png',
          content: '项目档案容易被篡改，不能作为调查依据。'
        }
      ],
      frameImg: '/static/img/productCenter/a-7.png',
      technology: '/static/img/productCenter/a-6.png',
      characteristic: [
        {
          img: '/static/img/productCenter/b-7.png',
          title: '独立',
          content: '电子档案必须具有独立性，完全独立的系统'
        },
        {
          img: '/static/img/productCenter/b-8.png',
          title: '规范',
          content:
            '按照公共资源全流程电子化交易服务和监管的需求，按照国家相关政策法规要求和技术标准'
        },
        {
          img: '/static/img/productCenter/b-9.png',
          title: '安全',
          content: '系统具备严格的用户身份验证和权限控制机制'
        },
        {
          img: '/static/img/productCenter/b-10.png',
          title: '协作',
          content:
            '按规定及时归档，系统可自动进行考核分析，无需交易中心档案管理人员核验数据，大大降低归档工作量。'
        },
        {
          img: '/static/img/productCenter/b-11.png',
          title: '创新',
          content:
            '电子档案数据上链保存，任何人无法篡改；系统提供支付宝查证渠道确保查证渠道，确保无人作弊；从归档、查证、利用三个方面彻底解决电子档案的信任问题。'
        }
      ],
      caseList: []
    }
  },
  methods: {
    freeTrial () {
      console.log('免费试用')
    },
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['公共资源交易电子档案系统']
      }
      this.swiperData = []
      this.$api.getBannerInfo(params).then(({ data: res }) => {
        this.loading = false
        if (res.code !== 0) {
          return console.error(res)
        }
        this.swiperData = res.data
      }).catch((err) => {
        this.loading = false
        console.error(err)
      })
    },
    // 获取案例 - 公共资源交易电子档案系统
    getCaseList () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.caseTypes['公共资源交易电子档案系统']
      }
      this.$api.getCaseInfo(params).then(({ data: res }) => {
        this.loading = false
        if (res.code !== 0) {
          return console.error(res)
        }
        this.caseList = res.data
      }).catch((err) => {
        this.loading = false
        console.error(err)
      })
    }
  },
  mounted () {
    this.getSwiperData()
    this.getCaseList()
  }
}
</script>

<style lang="less" scoped>
.service-system {
  .banner100vw();
  .productadvantage-bg {
    &::before {
      background: url('/static/img/theSolution/a-1.png') no-repeat center center /
          100% auto,
        linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    }
  }
}
</style>
