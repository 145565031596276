<template>
  <div class="app-scenarios">
    <v-subtitle
      zh="应用场景"
      en="Application scenarios"
      type="mini"
    />
    <v-scenarios :data="scenariosData" />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vScenarios from './scenarios.vue'
export default {
  components: {
    vSubtitle,
    vScenarios
  },
  props: {
    scenariosData: {
      type: Object,
      default: () => ({
        id: '1',
        img: '/static/img/productCenter/c-1.png',
        title: '实现招投标过程电子化',
        content:
          '对招投标过程所有资料上链存储，并保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。'
      })
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.app-scenarios {
  padding-top: 50px;
}
</style>
